import { toast, Slide } from "react-toastify";
import ToastContent from "@src/views/ui-elements/toast/ToastContent";
import Avatar from "@components/avatar";

// ** Exchanges Icons
import binance from "@src/assets/images/exchanges/64x64/binance.png";
import bitmex from "@src/assets/images/exchanges/64x64/bitmex.png";
import bitmexTestnet from "@src/assets/images/exchanges/64x64/bitmex-testnet.png";
import bybit from "@src/assets/images/exchanges/64x64/bybit.png";
import ftx from "@src/assets/images/exchanges/64x64/ftx.png";
import kraken from "@src/assets/images/exchanges/64x64/kraken.png";

import { Exchange } from "../constants/exchange";

export const CoinAvatar = ({ coin, img, icon, color }) => {
  return coin.length <= 5 ? (
    <Avatar className="mr-1" img={img} icon={icon} color={color} size="sm" />
  ) : null;
};

/**
 * Round number to N decimal places, by default 2 decimal places
 * @param {number} num number to round
 * @param {number} decimal number of decimal places, default 2
 * @returns {number} return rounded number with decimal places
 */
export const roundNumber = (num, decimal = 2) => {
  const nDecimal = Math.pow(10, decimal);
  return Math.round((num + Number.EPSILON) * nDecimal) / nDecimal;
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Remove all props that are undefined or null from an object
export const cleanObject = (obj) => {
  return Object.fromEntries(
    /* eslint eqeqeq: 0 */
    Object.entries(obj).filter(([_, v]) => v != null)
  );
};

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
};

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "ADMIN") return "/";
  if (userRole === "USER") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const getExchangeOptions = (exchange) => {
  switch (exchange) {
    case Exchange.BINANCE:
      return { value: Exchange.BINANCE, label: "Binance", img: binance };
    case Exchange.BINANCE_TESTNET:
      return {
        value: Exchange.BINANCE_TESTNET,
        label: "Binance Testnet",
        img: binance,
      };
    case Exchange.BINANCE_FUTURES:
      return {
        value: Exchange.BINANCE_FUTURES,
        label: "Binance Futures",
        img: binance,
      };
    case Exchange.BINANCE_FUTURES_TESTNET:
      return {
        value: Exchange.BINANCE_FUTURES_TESTNET,
        label: "Binance Futures Testnet",
        img: binance,
      };
    case Exchange.BITMEX:
      return { value: Exchange.BITMEX, label: "BitMEX", img: bitmex };
    case Exchange.BITMEX_TESTNET:
      return {
        value: Exchange.BITMEX_TESTNET,
        label: "BitMEX Testnet",
        img: bitmexTestnet,
      };
    case Exchange.BYBIT:
      return { value: Exchange.BYBIT, label: "Bybit", img: bybit };
    case Exchange.BYBIT_TESTNET:
      return {
        value: Exchange.BYBIT_TESTNET,
        label: "Bybit Testnet",
        img: bybit,
      };
    case Exchange.FTX:
      return { value: Exchange.FTX, label: "FTX", img: ftx };
    case Exchange.KRAKEN:
      return { value: Exchange.KRAKEN, label: "Kraken", img: kraken };
    default:
      return { value: "", label: "Select Exchange", img: "" };
  }
};

// ** renders exhcange column
export const renderExchange = (exchangeIcon) => {
  return (
    <Avatar
      color="white"
      className="mr-50"
      img={exchangeIcon}
      imgClassName="cursor-default"
      width="32"
      height="32"
    />
  );
};

export const errorHandler = (error, autoClose = 5000) => {
  let message = error?.message || '"Oops! Something went wrong';
  if (Array.isArray(message)) message = message.join(".");
  toast.error(
    <ToastContent
      title={"Error."}
      description={message || '"Oops! Something went wrong.'}
      type="error"
    />,
    { transition: Slide, hideProgressBar: true, autoClose, icon: false }
  );
};

export const successAlert = (title, message, autoClose = 3000) => {
  toast.success(
    <ToastContent
      title={title || "Success."}
      description={message || '"Operation performed successfully.'}
      type="success"
    />,
    { transition: Slide, hideProgressBar: true, autoClose, icon: false }
  );
};

export const obscureEmail = (email) => {
  const [name, domain] = email.split("@");
  return `${name[0]}${name[1]}${new Array(name.length - 1).join("*")}@${
    domain[0]
  }${new Array(domain.length).join("*")}`;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const percDiffBetweenTwoNumbers = (num1, num2) => {
  return roundNumber([(num2 - num1) / num1] * 100);
};

export const addPercToNumber = (perc, number) => {
  return roundNumber(number + (number / 100) * perc);
};

export const subtractPercToNumber = (perc, number) => {
  return roundNumber(number - (number / 100) * perc);
};
