// ** Initial State
const initialState = {
  orders: [],
  total: 1,
  params: {
    direction: "asc",
    page: 1,
    per_page: 10,
    sort: "_id",
  },
};

const tradeHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ORDERS":
      return {
        ...state,
        orders: action.orders,
        params: action.params,
        total: action.total,
      };
    default:
      return { ...state };
  }
};
export default tradeHistoryReducer;
