// ** Initial State
const initialState = {
  apikeys: [],
  selectedApikey: {},
  total: 1,
  activeSubscription: null,
  exchanges: [],
  params: {
    direction: "asc",
    page: 1,
    per_page: 10,
    sort: "_id",
  },
};

const ApikeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_APIKEYS":
      return {
        ...state,
        apikeys: action.apikeys,
        params: action.params,
        total: action.total,
      };
    case "UPDATE_APIKEYS":
      return { ...state };
    case "REORDER_APIKEYS":
      return { ...state, apikeys: action.apikeys };
    case "SELECT_APIKEY":
      return { ...state, selectedApikey: action.apikey };
    case "SET_EXCHANGES":
      return { ...state, exchanges: action.exchanges };
    case "GET_ACTIVE_SUBSCRIPTION":
      return { ...state, activeSubscription: action.activeSubscription }
    default:
      return state;
  }
};
export default ApikeyReducer;
