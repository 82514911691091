const initialState = {
  exchanges: [],
  apikeys: [],
  pairs: [],
  form: {
    symbol: { value: "", label: "Select Coin Pairs" },
  },
  formOptions: {},
  orderPayload: "",
  currentPrice: 0,
  webhookURL: "https://api.bitexbot.org/webhook/trade",
};

const tradeWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRADEWIZARD_DATA":
      return {
        ...state,
        apikeys: action.apikeys,
        exchanges: action.exchanges,
        formOptions: action.formOptions,
      };
    case "GET_FORM_OPTIONS":
      return {
        ...state,
        formOptions: action.formOptions,
      };
    case "GET_EXCHANGES":
      return {
        ...state,
        exchanges: action.exchanges,
      };
    case "GET_EXCHANGE_PAIRS":
      return {
        ...state,
        pairs: action.pairs,
      };
    case "GET_APIKEYS":
      return {
        ...state,
        apikeys: action.apikeys,
      };
    case "SET_FORM_VALUES":
      return {
        ...state,
        form: {
          ...state.form,
          ...action.values,
        },
      };
    case "SET_ORDER_PAYLOAD":
      return {
        ...state,
        orderPayload: action.orderPayload,
      };
    case "SET_CURRENT_PRICE":
      return {
        ...state,
        currentPrice: action.currentPrice,
      };
    case "RESET_PAIRS":
      return {
        ...state,
        pairs: action.pairs,
      };
    default:
      return { ...state };
  }
};
export default tradeWizardReducer;
