// ** Initial State
const initialState = {
  selectedUser: null,
  twoFactor: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, selectedUser: action.selectedUser };
    case "GET_QRCODE":
      return { ...state, twoFactor: action.twoFactor };
    default:
      return { ...state };
  }
};
export default users;
