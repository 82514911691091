import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import errorImg from "@src/assets/images/pages/error.svg";
import { DefaultRoute } from "@src/router/routes";
import LogoTextWrapper from "@src/views/ui-elements/logo/LogoText.js";
import "@styles/base/pages/page-misc.scss";

const Error = () => {
  return (
    <div className="misc-wrapper">
      <LogoTextWrapper />
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">Page Not Found 🕵🏻‍♀️</h2>
          <p className="mb-2">
            Oops! 😖 The requested URL was not found on this server.
          </p>
          <Button
            tag={Link}
            to={DefaultRoute}
            color="primary"
            className="btn-sm-block mb-2"
          >
            Back to home
          </Button>
          <img className="img-fluid" src={errorImg} alt="Not authorized page" />
        </div>
      </div>
    </div>
  );
};
export default Error;
