import { lazy } from "react";

const AppRoutes = [
  {
    path: "/bot",
    component: lazy(() => import("../../views/apps/bots")),
    meta: {
      navbarHidden: true,
    },
  },
  // {
  //   path: "/bot-beta",
  //   component: lazy(() => import("../../views/apps/bots")),
  //   meta: {
  //     navbarHidden: true,
  //   },
  // },
  {
    path: "/trade-wizard",
    component: lazy(() => import("../../views/apps/trade-wizard")),
  },
  {
    path: "/trade-history",
    component: lazy(() => import("../../views/apps/trade-history")),
  },
  {
    path: "/apikey",
    component: lazy(() => import("../../views/apps/apikey")),
  },
  {
    path: "/subscription",
    component: lazy(() => import("../../views/apps/subscription")),
  },
  {
    path: "/referral",
    component: lazy(() => import("../../views/apps/referral")),
  },
  {
    path: "/profile",
    component: lazy(() => import("../../views/apps/user/edit")),
  },
  {
    path: "/two-factor/setup",
    component: lazy(() =>
      import("../../views/apps/user/edit/two-factor-wizard")
    ),
  },
];

export default AppRoutes;
