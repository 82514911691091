// ** Initial State
const initialState = {
  exchanges: [],
  pairs: [],
  balance: null,
  orderBook: null,
  subscription: null,
};

const BotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONNECTED_EXCHANGES":
      return {
        ...state,
        exchanges: action.exchanges,
      };
    case "GET_EXCHANGE_PAIRS":
      return {
        ...state,
        pairs: action.pairs,
      };
    case "GET_SUBSCRIPTION": {
      return {
        ...state,
        subscription: action.subscription,
      };
    }
    case "RESET_PAIRS":
      return {
        ...state,
        pairs: initialState.pairs,
      };
    case "GET_AVALIABLE_BALANCE": {
      return {
        ...state,
        balance: action.balance,
      };
    }
    case "RESET_BALANCE": {
      return {
        ...state,
        balance: initialState.balance,
      };
    }
    case "SET_ORDERBOOK": {
      return {
        ...state,
        orderBook: action.orderBook,
      };
    }
    case "RESET_ORDERBOOK": {
      return {
        ...state,
        orderBook: initialState.orderBook,
      };
    }
    default:
      return state;
  }
};
export default BotReducer;
