// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import layout from "./layout";
import navbar from "./navbar";
import apikey from "@src/views/apps/apikey/store/reducer";
import referral from "@src/views/apps/referral/store/reducer";
import bots from "@src/views/apps/bots/store/reducer";
import tradeWizard from "@src/views/apps/trade-wizard/store/reducer";
import tradeHistory from "@src/views/apps/trade-history/store/reducer";
import subscription from "@src/views/apps/subscription/store/reducer";
import users from "@src/views/apps/user/store/reducer";

const rootReducer = combineReducers({
  auth,
  layout,
  navbar,
  apikey,
  referral,
  bots,
  tradeWizard,
  tradeHistory,
  subscription,
  users,
});

export default rootReducer;
