import { Bell, Check, X, AlertTriangle, Info } from "react-feather";
import { Fragment } from 'react';
import Avatar from "@components/avatar";

const getType = (type) => {
    switch (type) {
        case "primary": 
        default:
            return <Bell size={12} />;
        case "success": return <Check size={12} />;
        case "danger": return <X size={12} />;
        case "warning": return <AlertTriangle size={12} />;
        case "info": return <Info size={12} />;
    }
}

const ToastContent = ({ title, type = "primary", description, textmuted }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color={type === 'error' ? 'danger' : type} icon={getType(type)} />
        <h6 className="toast-title">{title}</h6>
      </div>
      {textmuted && <small className="text-muted">{textmuted}</small>}
    </div>
    {description && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {description}
        </span>
      </div>
    )}
  </Fragment>
);

export default ToastContent