const LogoTextWrapper = (props) => {
  const { to, size } = props;
  const logo = require(`@src/assets/images/logo/bitexbot-logo-text${
    size ? `-${size}` : ""
  }.png`).default;
  return (
    <a href={to || process.env.REACT_APP_WEBSITE_URL} className="brand-logo">
      <img src={logo} alt="Bitexbot logo" />
    </a>
  );
};

export default LogoTextWrapper;
