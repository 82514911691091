export const Exchange = {
  BINANCE: "BINANCE",
  BINANCE_TESTNET: "BINANCE_TESTNET",
  BINANCE_FUTURES: "BINANCE_FUTURES",
  BINANCE_FUTURES_TESTNET: "BINANCE_FUTURES_TESTNET",
  BITMEX: "BITMEX",
  BITMEX_TESTNET: "BITMEX_TESTNET",
  BYBIT: "BYBIT",
  BYBIT_TESTNET: "BYBIT_TESTNET",
  FTX: "FTX",
  KRAKEN: "KRAKEN",
};
