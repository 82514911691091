// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Error Handler
import { ErrorBoundary } from "react-error-boundary";
import { errorHandler } from "./utility/Utils";
import Error from "./views/pages/misc/Error";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <IntlProviderWrapper>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <ErrorBoundary
              onError={errorHandler}
              fallbackRender={({ error, resetErrorBoundary }) => (
                // this next line is why the fallbackRender is useful
                // resetErrorBoundary();
                <Error resetErrorBoundary={resetErrorBoundary} />
              )}
            >
              <LazyApp />
            </ErrorBoundary>
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </IntlProviderWrapper>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
