import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../../views/pages/authentication/Login")),
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/2fa",
    component: lazy(() => import("../../views/pages/authentication/TwoFactor")),
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/logout",
    component: lazy(() => import("../../views/pages/authentication/Logout")),
    layout: "BlankLayout",
  },
  {
    path: "/signup",
    component: lazy(() => import("../../views/pages/authentication/Register")),
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/forgot-password",
    component: lazy(() =>
      import("../../views/pages/authentication/ForgotPassword")
    ),
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import("../../views/pages/authentication/ResetPassword")
    ),
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  // {
  //   path: '/misc/coming-soon',
  //   component: lazy(() => import('../../views/pages/misc/ComingSoon')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  // {
  //   path: '/misc/not-authorized',
  //   component: lazy(() => import('../../views/pages/misc/NotAuthorized')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  // {
  //   path: '/misc/maintenance',
  //   component: lazy(() => import('../../views/pages/misc/Maintenance')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  // {
  //   path: '/misc/error',
  //   component: lazy(() => import('../../views/pages/misc/Error')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  {
    path: "/subscription/thankyou",
    component: lazy(() => import("../../views/pages/misc/ThankYou")),
    layout: "BlankLayout",
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/emailconfirm",
    component: lazy(() =>
      import("../../views/pages/authentication/EmailConfirm")
    ),
    layout: "BlankLayout",
    meta: {
      publicRoute: true,
    },
  },
  // {
  //   path: '/terms-conditions',
  //   component: lazy(() => import('../../views/pages/misc/TermsConditions')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  // {
  //   path: '/disclaimer',
  //   component: lazy(() => import('../../views/pages/misc/Disclaimer')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  // {
  //   path: '/privacy-policy',
  //   component: lazy(() => import('../../views/pages/misc/PrivacyPolicy')),
  //   layout: 'BlankLayout',
  //   meta: {
  //     publicRoute: true
  //   }
  // },
];

export default PagesRoutes;
