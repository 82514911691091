const initialState = {
  activeSubscription: null,
  user: null,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACTIVE_SUBSCRIPTION":
      return {
        ...state,
        activeSubscription: action.activeSubscription,
      };
    case "GET_USER":
      return {
        ...state,
        user: action.user,
      };
    default:
      return { ...state };
  }
};
export default subscriptionReducer;
